@use "./config/" as *;
@forward "./main.scss";
.waf-certificate-detail {
  padding: 0 0 var(--space-20);
  margin-bottom: 0;
  @extend %page-head-blue-bg;
  .waf-body {
    margin-top: -8rem;
    border-radius: unset;
    background-color: unset;
  }
  .download {
    &-details {
      @extend %p-4;
      @extend %mt-8;
    }
    &-body-wrapper {
      .card-item {
        min-height: auto;
        .title {
          @extend %mb-0;
          @extend %primary-50;
        }
        .btn-link {
          &::before {
            content: '\e84d';
          }
        }
      }
      @extend %event-card-small;
    }
  }
  .item-list,
  .download-details {
    @extend %border-radius-m;
    @extend %bg-neutral-0;
  }
  .item {
    @extend %relative;
    &:not(:last-child) {
      @extend %mb-20;
    }
    &-list {
      @extend %p-6-4;
    }
    .title {
      color: var(--neutral-100);
      @extend %title-40;
    }
    .desc {
      @extend %body-l;
    }
    .title,
    .desc {
      @extend %mb-8;
    }
  }
  .badge-item {
    .btn-link {
      &::before,
      &::after {
        content: unset;
      }
    }
  }
  .table {
    @include border(1, neutral-20);
    @extend %border-radius-m;
    @extend %hidden;
    &-title {
      @include border(1, neutral-20, 10, bottom);
      @extend %title-50;
      @extend %bg-neutral-5;
      @extend %text-center;
      @extend %m-auto;
      @extend %p-4;
    }
    &-wrap {
      @extend %flex;
    }
    &-right {
      background: transparent;
      flex-grow: 1;
    }
    &-left {
      max-width: 10.8rem;
      @include border(1, neutral-20, 10, right);
      @extend %bg-neutral-5;
      .text {
        @extend %text-mb;
      }
    }
    &-data {
      justify-content: flex-start;
      text-align: left;
      height: 7.2rem;
      @include border(1, neutral-20, 10, bottom);
      @extend %p-4;
      &:last-of-type {
        border-bottom: 0;
      }
    }
    .logo {
      width: 2.4rem;
      aspect-ratio: 1/1;
      @extend %circle-radius;
    }
    .text {
      @extend %flex-n-c;
      @extend %gap-2;
    }
  }
}
@include mq(col-tablet) {
  .waf-certificate-detail {
    .download {
      &-body-wrapper {
        display: grid;
        grid-template-columns: 40% auto;
        gap: var(--space-6);
      }
      &-details {
        height: fit-content;
        margin-top: 0;
        padding: var(--space-12);
      }
    }
    .item {
      &-list {
        padding: var(--space-6);
      }
    }
  }
}